/* eslint-disable prettier/prettier */

import { Outlet, useLocation, useNavigate } from "react-router-dom"
import Logo from "../../assets/images/logo.svg"
import { Card } from "antd"
import Back from "../../assets/icons/Left.svg"
import { useCallback, useLayoutEffect } from "react"
import { ROUTE } from "../constants"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { setAllAuthKey } from "../../store"
import authImg from "../../assets/images/authImg.png"

const Auth: React.FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const state = useAppSelector((state) => {
    return state.auth
  })

  const setChildrenData = useCallback(
    (formMethod: "POST" | "GET", postUrl: string): void => {
      // dispatch the formMethod and the postUrl for form submission when the page mouths
      dispatch(
        setAllAuthKey({
          ...state,
          formMethod,
          postUrl,
        }),
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  )

  const navigate = useNavigate()
  const info = sessionStorage.getItem("***")

  useLayoutEffect(() => {
    // retreive token from cookies
    document.title =
      location.pathname === ROUTE.INDEX
        ? "Sign In - Translite"
        : location.pathname
            .charAt(0)
            .toUpperCase()
            .replaceAll("/", "")
            .replaceAll("-", " ") +
          location.pathname
            .slice(1)
            .toLowerCase()
            .replaceAll("/", "")
            .replaceAll("-", " ") +
          " - Translite"

    // check if use is logged in
    if (info && info.length > 30) {
      navigate(ROUTE.DASHBOARD, {
        replace: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, info, dispatch])

  return (
    <div className="bg-[#4C469B] min-h-[100svh] overflow-auto">
      <header className="h-16 flex items-center px-10">
        <img src={Logo} alt="logo" className="" />
      </header>
      <div className="px-10 h-full xl:translate-y-0 translate-y-[25%]">
        <div className="w-[65%] fixed left-[50%] mx-auto translate-x-[-50%] hidden xl:block">
          <img src={authImg} alt="" className="" />
        </div>
        <Card
          className={`xl:w-[35%] xl:ml-[65%] auth-card py-5 px-5 max-w-[35rem] mx-auto relative rounded-[16px] overflow-auto`}
        >
          {location.pathname !== ROUTE.INDEX && (
            <img
              src={Back}
              onClick={() => window.history.back()}
              alt=""
              className="cursor-pointer hover:scale-90 hover:transition-all absolute left-4 w-10 h-10"
            />
          )}
          <Outlet context={setChildrenData} />
        </Card>
      </div>
    </div>
  )
}

export default Auth
